<template>
  <b-col :md="formField.size">
    <label class="text-muted" for="cpf_aluno">{{ formField.label }}</label>

    <input
      v-if="formField.type == 'text'"
      :type="formField.type"
      class="form-control"
      :class="{ 'border border-danger': errors.includes(formField.model) }"
      required
      placeholder=""
      :value="value"
      @input="$emit('input', $event.target.value)"
      @submit="submit"
    />
    <!-- <b-form-datepicker
      v-if="formField.type == 'date'"
      :class="{ 'border border-danger': errors.includes(formField.model) }"
      id="datepicker-placeholder"
      placeholder="Choose a date"
      locale="pt-BR"
      @context="check"
    ></b-form-datepicker> -->
    <!-- <input
      v-if="formField.type == 'date'"
      type="number"
      class="form-control"
       :class="{ 'border border-danger': errors.includes(formField.model) }"
      required
      placeholder=""
      :value="value"
      @input="$emit('input', $event.target.value)"
      
    /> -->
    <the-mask
      :masked="formField.masked || false"
      :class="{ 'border border-danger': errors.includes(formField.model) }"
      class="form-control"
      v-if="formField.type !== 'text' && formField.type !== 'select'"
      :placeholder="formField.placeholder || ''"
      :value="value"
      @input="check"
      :mask="[...formField.mask]"
    />

    <b-form-select
      v-if="formField.type == 'select'"
      :class="{ 'border border-danger': errors.includes(formField.model) }"
      :value="value || null"
      @change="$emit('input', $event)"
    >
      <b-form-select-option :value="null" disabled
        >Selecione</b-form-select-option
      >
      <b-form-select-option
        v-for="option in formField.selectOptions"
        :key="option.id"
        :value="option.value"
        >{{ option.description }}</b-form-select-option
      >
    </b-form-select>
    <small class="text-danger input-error" v-if="errors.includes(formField.model)"
      >Campo Obrigatório</small
    >
  </b-col>
</template>

<script>
import { TheMask } from 'vue-the-mask'
import moment from 'moment'
export default {
  props: {
    formField: {
      type: null,
    },
    value: {
      type: null,
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    TheMask,
  },  
  
  methods: {
    check(e) {
      this.$emit('input', e)
    },
    submit(e) {
      e.preventDefault()
    },
    test() {},
  },
}
</script>

<style>
</style>